'use strict';

(function (app, $) {
    var $cache = {},
        $productSearchResult = $('#jwsdw-productSearchResult'),
        $contentSearchResult = $('#jwsdw-contentSearchResult'),
        $productRefinementsPicker = $('.jwsdw-searchRefinementsPicker'),
        $gridWrapper = $('.jwsdw-product-tiles-grid-wrapper'),
        pageType = $productSearchResult.data('page-type'),
        xDown = null,
        yDown = null;

    var intersectionObserver;
    var $loadMore;
    var loadMoreUrl, loadMoreHistoryUrl;

    app.search = {};

    /**
     * @description Method to initialize the Intersection Observer for the 'Load More' button
     * @param {Object} $unobserveElement used to unobserve the old 'Load More' button
     * @returns {void}
     */
    function initLoadMoreIntersectionObserver($unobserveElement) {
        var isInfiniteScrollEnabled,
            options;
        $loadMore = $productSearchResult.find('.jwsdw-loadMore-button');
        isInfiniteScrollEnabled = $loadMore.data('infinite-scroll');
        options = {
            'root': null,
            'rootMargin': '0px 0px 400px 0px', // buffer zone around the observed element
            'threshold': 0.1 // Trigger when at least 10% of the target is visible
        };

        if (isInfiniteScrollEnabled) {
            loadMoreUrl = $loadMore.data('href');
            loadMoreHistoryUrl = $loadMore.data('hrefHistory');
            if (!intersectionObserver) {
                intersectionObserver = new IntersectionObserver(function(entries, observer) {
                    entries.forEach(function(entry) {
                        if (entry.isIntersecting && !$loadMore.hasClass('jwsdw-spinner')) {
                            app.search.loadMoreProducts(loadMoreUrl, loadMoreHistoryUrl, $loadMore);
                        }
                    });
                    return observer;
                }, options);
            }

            // Unobserve the old 'Load More' button
            if ($unobserveElement && $unobserveElement.length) {
                intersectionObserver.unobserve($unobserveElement[0]);
            }

            // Observe the target element if it exists
            if ($loadMore.length) {
                intersectionObserver.observe($loadMore[0]);
            }

        }
    }

    /**
     * @description Method to attach event handlers
     * @returns {Boolean} true when all handlers have been attached
     */
    function initializeEvents() {

        initLoadMoreIntersectionObserver();

        // clear filters button
        $productSearchResult.on('click', '.jwsdw-productSearchResult-clear-filters', function (e) {
            var refineUrl = $(this).attr('href'),
                uri = window.jwsdwUtil.urlUtils.getUri(refineUrl);

            e.preventDefault();
            app.search.updateProductSearchResult(uri.fullUrl);
        });

        // change filters button
        $productSearchResult.on('click', '.jwsdw-productSearchResult-change-filters', function (e) {
            e.preventDefault();
            window.jwsdwMediator.publish('openPicker', 'searchRefinementsPicker');
        });


        // binding product tile to touchStart and touchMove events
        bindTouchEvents();

        // handler for setting category cookie for PDP breadcrumbs
        $productSearchResult.on('click', '.jwsdw-product-variation', function () {
            var masterId = $(this).data('master-id'),
                categoryId = $('.jwsdw-attributeRefinements').data('category-id');
            if ($('.jwsdw-productRefinement-size input[name^=jwsdw-refinement-value]:checked').length) {
                let hash = [];
                for (let i = 0; i < $('.jwsdw-productRefinement-size input[name^=jwsdw-refinement-value]:checked').length; i++) {
                    hash.push($($('.jwsdw-productRefinement-size input[name^=jwsdw-refinement-value]:checked')[i]).attr('data-refinement-value').trim());
                }
                $(this).find('a').each(function () {
                    let url = $(this).attr('href') + '#size=' + hash.join('|');
                    $(this).attr('href', url);
                });
            }
            window.jwsdwUtil.setCookie('pid-' + masterId, categoryId, 1);
        });

        // handler for loading more product tiles
        $productSearchResult.on('click', '.jwsdw-loadMore-button', function () {
            var $this = $(this),
                url = $this.data('href'),
                historyUrl = $this.data('hrefHistory');

            app.search.loadMoreProducts(url, historyUrl, $this);
        });

        // handler for loading more content search results
        $contentSearchResult.on('click', '.jwsdw-contentSearchResult-loadMore-button', function () {
            var $this = $(this),
                url = $this.data('href'),
                historyUrl = $this.data('hrefHistory');

            app.search.loadMoreContent(url, historyUrl, $this);
        });

        // handle refinement (filter) buttons that open the picker and make sure the right filter panels are opened
        $productSearchResult.on('click', '.jwsdw-productRefinements-pickerButton', function () {
            var $this = $(this),
                refinements,
                openRefinements,
                activeRefinements;
            // get refinements that should be opened initially
            refinements = $this.data('refinements').split(',').map(function (refinement) {
                return '.jwsdw-productRefinement-' + refinement;
            }),
            openRefinements = $productRefinementsPicker.find(refinements.join(',')),
            activeRefinements;
            if ($this.is('#jwsdw-productSearchResult-filterButton')) {
                activeRefinements = $productRefinementsPicker.find('input[name^=jwsdw-refinement-value]:checked').parents('.jwsdw-productRefinement');
                openRefinements = openRefinements.add(activeRefinements);
            }

            $productRefinementsPicker.find('.jwsdw-productRefinement input[name=accordion-tab]:checked').prop('checked', false);
            openRefinements.find('input[name=accordion-tab]').prop('checked', true);
            if ($this.hasClass('jwsdw-filter-button-allFilters')) {
                $('.jwsdw-refinementsBarContainer').empty().addClass('jws-hidden');
                $('.jwsdw-productRefinements-pickerButton').removeClass('jwsdw-selected-refinement');
                window.jwsdwMediator.publish('openPicker', 'searchRefinementsPicker');
            } else if (!$this.hasClass('jwsdw-selected-refinement')) {
                $('.jwsdw-refinementsBarContainer').empty().addClass('jws-hidden');
                $('.jwsdw-productRefinements-pickerButton').removeClass('jwsdw-selected-refinement');
                $this.addClass('jwsdw-selected-refinement');
                $this.closest('div').find('.jwsdw-refinementsBarContainer').html(openRefinements.find('.jws-accordionTabContent').html()
                    .replace(/for="/g, 'for="bar_').replace(/\id="/g, 'id="bar_').replace(/jws-grid-large-column-4/g, 'jws-grid-large-column-6'))
                    .removeClass('jws-hidden');
            } else {
                $this.removeClass('jwsdw-selected-refinement');
                $this.closest('div').find('.jwsdw-refinementsBarContainer').addClass('jws-hidden');
            }

            closeAllSelect($('.select-selected'));
        });

        // handle price range active refinements (when two ranges are selected ranges in between those need to be selected as well)
        $productRefinementsPicker.on('change', '.jwsdw-productRefinement-price .jwsdw-productRefinement-value input[name^=jwsdw-refinement-value]', function () {
            var $this = $(this),
                allValues = $this.parents('.jwsdw-productRefinement-price').find('.jwsdw-productRefinement-value input[name^=jwsdw-refinement-value]'),
                activeValues = allValues.filter(':checked'),
                fromValue,
                toValue,
                minValue,
                maxValue,
                minRefinementValue,
                maxRefinementValue;

            activeValues.each(function (j, activeValue) {
                minRefinementValue = $(activeValue).data('refinementValueFrom');
                minValue = (typeof minValue !== 'number' || minRefinementValue < minValue) ? minRefinementValue : minValue;
                maxRefinementValue = $(activeValue).data('refinementValueTo');
                maxValue = (typeof maxValue !== 'number' || maxRefinementValue > maxValue) ? maxRefinementValue : maxValue;
            });

            allValues.each(function (i, value) {
                fromValue = $(value).data('refinementValueFrom');
                toValue = $(value).data('refinementValueTo');
                if ((fromValue >= minValue) && (toValue <= maxValue)) {
                    $(value).prop('checked', true);
                }
            });
        });

        //handler for choosing the performance results filter
        $(document).on('click', '#jwsdw-filter-results-all', function () {
            $('.jwsdw-filter-results').removeClass('jwsdw-results-selected').addClass('jws-colorGrey3 jws-cursorPointer');
            $('#jwsdw-filter-results-all').removeClass('jws-colorGrey3 jws-cursorPointer').addClass('jwsdw-results-selected');
            $('.jwsdw-productRefinement-refinementAktivitaet input[type="checkbox"]').prop('checked', false);
            $(".jwsdw-searchRefinementsPicker-apply").trigger('click');
            updatePerformanceTracking(window.dynamic_tm_data.categoryid, "All");
        });

        /**
        * @description Method to activate unchecked refinement1 inputs and deactivate checked refinement2 inputs.
        * @param {Object} refinement1 first refinement
        * @param {Object} refinement2 second refinement
        * @returns {void}
        */
        function updateRefinementInputs(refinement1, refinement2) {
            for (let i = 0; i < refinement1.length; i++) {
                if ($(`input[data-refinement-value="${refinement1[i]}"]`).is(':checked')) {
                    continue;
                }
                $(`#jwsdw-searchRefinementsPicker label:contains(${refinement1[i]})`).trigger('click');
            }

            for (let j = 0; j < refinement2.length; j++) {
                if ($(`input[data-refinement-value=${refinement2[j]}]`).is(':checked')) {
                    $(`#jwsdw-searchRefinementsPicker label:contains(${refinement2[j]})`).trigger('click');
                }
            }
        }

        $(document).on('click', '#jwsdw-filter-results-performance.has-products', function () {
            var performanceRefinements = $(this).data('refinement-values').split('|');
            var urbanRefinements = $('#jwsdw-filter-results-urban').data("refinement-values").split('|');
            $('.jwsdw-filter-results').removeClass('jwsdw-results-selected').addClass('jws-colorGrey3 jws-cursorPointer');
            $('#jwsdw-filter-results-performance').removeClass('jws-colorGrey3 jws-cursorPointer').addClass('jwsdw-results-selected');
            updateRefinementInputs(performanceRefinements, urbanRefinements);
            $(".jwsdw-searchRefinementsPicker-apply").trigger('click');
            updatePerformanceTracking(window.dynamic_tm_data.categoryid, "Functional");
        });

        $(document).on('click', '#jwsdw-filter-results-urban.has-products', function () {
            var performanceRefinements = $('#jwsdw-filter-results-performance').data("refinement-values").split('|');
            var urbanRefinements = $(this).data("refinement-values").split('|');
            $('.jwsdw-filter-results').removeClass('jwsdw-results-selected').addClass('jws-colorGrey3 jws-cursorPointer');
            $('#jwsdw-filter-results-urban').removeClass('jws-colorGrey3 jws-cursorPointer').addClass('jwsdw-results-selected');
            updateRefinementInputs(urbanRefinements, performanceRefinements);
            $(".jwsdw-searchRefinementsPicker-apply").trigger('click');
            updatePerformanceTracking(window.dynamic_tm_data.categoryid, "Urban");
        });

        // clear all filters
        $(document).on('click', '.jwsdw-clearFilters, .jwsdw-searchRefinementsPicker-clear', function () {
            $productRefinementsPicker.find('.jwsdw-productRefinement-value input[name^=jwsdw-refinement-value]').prop('checked', false);
            _applyFilters();
        });

        // handle removed chips
        $(document).on('click', '.jwsdw-chips-item', function () {
            $productRefinementsPicker.find('.jwsdw-productRefinement-value input[id="' + $(this).attr('data-id') + '"]').prop('checked', false);
            if ($(this).is('.jwsdw-chips-items span[data-id*="jwsdw-refinementPrice-"]')) { // if the clicked chip is a refinementPrice, uncheck all the refinementPrice inputs
                $productRefinementsPicker.find('.jwsdw-productRefinement-value input[id*="jwsdw-refinementPrice-"]').prop('checked', false);
            }
            _applyFilters();
            $(this).hide(300);
        });

        // handle layout grid
        $(document).on('click', '.jwsdw-layoutGrid svg', function () {
            if ($(window).width() < 900) {
                window.jwsdwUtil.setCookie('gridSelectionMobile', '.' + $(this).attr('class').split(/\s+/)[0], 30);
            } else {
                window.jwsdwUtil.setCookie('gridSelection', '.' + $(this).attr('class').split(/\s+/)[0], 30);
            }
            handleLayoutSelection($(this), $(window).width() < 900);
            handleGridSystem();
        });

        window.addEventListener('load', function () {
            var keyValue = $(window).width() < 900 ? window.jwsdwUtil.getCookie('gridSelectionMobile') : window.jwsdwUtil.getCookie('gridSelection');
            if (keyValue) {
                handleLayoutSelection($(keyValue), $(window).width() < 900);
            }
            handleGridSystem();
        });

        // handle the apply filters button in the refinement picker
        $productRefinementsPicker.on('click', '.jwsdw-searchRefinementsPicker-apply', _applyFilters);

        $(document).on('click', '.jwsdw-activitiesSlider a', function () {
            $("#jwsdw-searchRefinementsPicker label:contains('" + $(this).data('refinementvalue') + "')").trigger('click');
            $(".jwsdw-searchRefinementsPicker-apply").trigger('click');
        });

        $(document).on('change', '.jwsdw-refinementsBarContainer input[type=checkbox], .jwsdw-refinementsBarContainer input[type=radio]', function () {
            window.refineUrl = app.search.getRefinementUrl($productRefinementsPicker.find('.jwsdw-searchRefinementsPicker-apply').data('searchBaseUrl'), $(this));
            $('.jwsdw-selected-refinement').removeClass('jwsdw-selected-refinement');
            app.search.updateProductSearchResult(window.refineUrl);
        });

        $(document).on('change', '.jwsdw-attributeRefinements .jws-accordionTabContent input[type=checkbox]', function () {
            window.refineUrl = app.search.getRefinementUrl($productRefinementsPicker.find('.jwsdw-searchRefinementsPicker-apply').data('searchBaseUrl'));
            $('.jwsdw-selected-refinement').removeClass('jwsdw-selected-refinement');
            app.search.updateProductSearchResultCount(window.refineUrl);
        });

        if (pageType === 'productSetHits' && $('.jwsdw-cm-outfitOfTheMonth-heroImage').length > 0) {
            $('.jwsdw-cm-outfitOfTheMonth-triangle')
                .removeClass('jws-showFromLarge')
                .addClass('jws-showFromMedium');
            $('.jwsdw-cm-outfitOfTheMonth-triangle-mobile')
                .removeClass('jws-hideFromLarge')
                .addClass('jws-hideFromMedium');
            $('.jwsdw-cm-outfitOfTheMonth-heroImage')
                .removeClass('jwsdw-width100')
                .removeClass('jwsdw-large-width50');
        }

        return true;
    }

    /**
     * Method get apply filters
     * @returns {void}
     */
    function _applyFilters() {
        var refineUrl = app.search.getRefinementUrl($productRefinementsPicker.find('.jwsdw-searchRefinementsPicker-apply').data('searchBaseUrl'));

        app.search.updateProductSearchResult(refineUrl);

        window.jwsdwMediator.publish('closePicker', 'searchRefinementsPicker', true, true);
    }
    /**
     * Method get touches
     * @param {Object} evt event
     * @returns {Boolean} returns true if there is touch event
     */
    function getTouches(evt) {
        return evt.touches || // browser API
            evt.originalEvent.touches; // jQuery
    }

    /**
     * Method handle touch start
     * @param {Object} evt event
     * @returns {void} returns nothing
     */
    function handleTouchStart(evt) {
        var firstTouch = getTouches(evt)[0];
        xDown = firstTouch.clientX;
        yDown = firstTouch.clientY;
    }

    /**
     * Method handle touch move / swipe event
     * @param {Object} evt event
     * @returns {void} returns nothing
     */
    function handleTouchMove(evt) {
        var $this = $(this),
            activeColorVariation = $this.find('.jwsdw-productTile-color-container .jwsdw-product-color-active'),
            xUp = evt.touches[0].clientX,
            yUp = evt.touches[0].clientY,
            xDiff = xDown - xUp,
            yDiff = yDown - yUp,
            colorVariationPrev = activeColorVariation.prev(),
            colorVariationNext = activeColorVariation.next();

        if (!xDown || !yDown) {
            return;
        }

        if (Math.abs(xDiff) > Math.abs(yDiff)) {
            if (xDiff > 0) {
                colorVariationNext.trigger('click');
            } else {
                colorVariationPrev.trigger('click');
            }
        }
        /* reset values */
        xDown = null;
        yDown = null;
    }

    /**
     * @function
     * @description Method to bind touch/move events
     * @returns {void}
     */
    function bindTouchEvents() {
        var $productTiles = $('.jwsdw-productTile');

        $productTiles.each(function (i, $productTile) {
            $productTile.addEventListener('touchstart', handleTouchStart, window.jwsdwUtil.supportsPassiveEvents() ? { 'passive': true } : false);
            $productTile.addEventListener('touchmove', handleTouchMove, window.jwsdwUtil.supportsPassiveEvents() ? { 'passive': true } : false);
        });
    }

    /**
     * @function
     * @description Method for all/performance/urban to add selected class
     * @param {Array} performanceRefinements refinements for performance
     * @param {Array} urbanRefinements refinements for urban
     * @returns {void}
     */
    function filterSelected(performanceRefinements, urbanRefinements) {
        let checkedBoxes = [];
        $('.jwsdw-productRefinement-refinementAktivitaet input[name="jwsdw-refinement-value"]:checked').each(function () {
            checkedBoxes.push($(this).data('refinement-value'));
        });
        if (checkedBoxes.length) {
            if (urbanRefinements.every(item => checkedBoxes.includes(item)) && performanceRefinements.every(item => checkedBoxes.includes(item))) {
                $('#jwsdw-filter-results-all').removeClass('jws-colorGrey3 jws-cursorPointer').addClass('jwsdw-results-selected');
            } else if (urbanRefinements.every(item => checkedBoxes.includes(item))) {
                $('#jwsdw-filter-results-urban').removeClass('jws-colorGrey3 jws-cursorPointer').addClass('jwsdw-results-selected');
            } else if (performanceRefinements.every(item => checkedBoxes.includes(item))) {
                $('#jwsdw-filter-results-performance').removeClass('jws-colorGrey3 jws-cursorPointer').addClass('jwsdw-results-selected');
            }
        } else {
            $('#jwsdw-filter-results-all').removeClass('jws-colorGrey3 jws-cursorPointer').addClass('jwsdw-results-selected');
        }
    }

    /**
     * @function
     * @description Method for swiper and all/performance/urban filters - initialize the refinement bar swiper and add the 'jwsdw-results-selected' class on the selected filter.
     * @returns {void}
     */
    function initRefinementBarAndSelectFilter() {
        let performanceRefinements = $('#jwsdw-filter-results-performance').length && $('#jwsdw-filter-results-performance').data("refinement-values").split('|'),
            urbanRefinements = $('#jwsdw-filter-results-urban').length && $('#jwsdw-filter-results-urban').data("refinement-values").split('|');
        initTheSwiper();
        filterSelected(performanceRefinements, urbanRefinements);
    }

    /**
     * @function
     * @description Method to generate refinement url
     * @param {String} baseUrl base url
     * @param {Object} $this element for refinement bar filters
     * @param {Array} refinementValues refinement Values to be used
     * @returns {String} refinement url
     */
    app.search.getRefinementUrl = function (baseUrl, $this, refinementValues) {
        var $refinementDefinitions,
            activeRefinements = {},
            controlFilters = {},
            searchParameters,
            firstSeparator = baseUrl.indexOf('?') > -1 ? '&' : '?';

        if ($this) {
            $('.jwsdw-productRefinement-' + $('.jwsdw-selected-refinement').data('refinements')).remove();
            $refinementDefinitions = $productRefinementsPicker.find('.jwsdw-productRefinement');
            $refinementDefinitions = $refinementDefinitions.add($this.closest('.jwsdw-refinementsBarContainer'));
        } else {
            $refinementDefinitions = $productRefinementsPicker.find('.jwsdw-productRefinement');
        }

        if (refinementValues) {
            $refinementDefinitions.push('refinementAktivitaetFromFilter');
        }

        // get filter refinement definition ids with active refinement values and save them in object
        $refinementDefinitions.each(function (index, refinementDefinition) {
            var $refinementDefinition = refinementDefinition !== 'refinementAktivitaetFromFilter' ? $(refinementDefinition) : 'refinementAktivitaetFromFilter',
                activeRefinementValues = refinementDefinition !== 'refinementAktivitaetFromFilter' ?
                    $refinementDefinition.find('.jwsdw-productRefinement-value input[name^=jwsdw-refinement-value]:checked') : refinementValues,
                refinementDefinitionId,
                values = [],
                fromValues = [],
                toValues = [];

            if (refinementDefinition === 'refinementAktivitaetFromFilter') {
                refinementDefinitionId = 'refinementAktivitaet';
            } else {
                refinementDefinitionId = $this && index === 0 ? $('.jwsdw-selected-refinement').data('refinements') :
                    $refinementDefinition.data('refinementDefinition');
                if (refinementValues && refinementDefinitionId === 'refinementAktivitaet') {
                    return;
                }
            }

            if (refinementDefinitionId !== 'price' && activeRefinementValues.length > 0) {
                if (refinementDefinition === 'refinementAktivitaetFromFilter') {
                    for (let i = 0; i < activeRefinementValues.length; i++) {
                        values.push(activeRefinementValues[i]);
                    }
                } else {
                    activeRefinementValues.each(function (j, activeRefinementValue) {
                        values.push($(activeRefinementValue).data('refinementValue'));
                    });
                }

                if (controlFilters[refinementDefinitionId] && $this) {
                    return;
                }

                controlFilters[refinementDefinitionId] = true;
                activeRefinements['prefn' + (index + 1)] = refinementDefinitionId;
                activeRefinements['prefv' + (index + 1)] = values.join('|');
            } else if (refinementDefinitionId === 'price' && activeRefinementValues.length > 0) {
                activeRefinementValues.each(function (j, activeRefinementValue) {
                    fromValues.push($(activeRefinementValue).data('refinementValueFrom') || 0);
                    toValues.push($(activeRefinementValue).data('refinementValueTo'));
                });
                activeRefinements.pmin = fromValues.sort(_sortAsc)[0];
                activeRefinements.pmax = toValues.sort(_sortAsc)[toValues.length - 1];
            }
        });

        // create parameters from object and concat them to the search base url
        searchParameters = $.param(activeRefinements);
        if (searchParameters !== '') {
            baseUrl += firstSeparator + searchParameters;
        }

        return baseUrl;
    };

    /**
     * @description Method to sort array of numbers ascending
     * @param {number} a value a
     * @param {number} b value b
     * @returns {number} sorting
     */
    function _sortAsc(a, b) {
        return a > b ? 1 : -1;
    }

    /**
     * @description Method to get active refinements
     * @returns {Object} activeRefinements
     */
    app.search.getActiveRefinement = function () {
        var $refinementDefinitions = $productRefinementsPicker.find('.jwsdw-productRefinement'),
            activeRefinements = {};

        // get filter refinement definition ids with active refinement values and save them in object
        $refinementDefinitions.each(function (index, refinementDefinition) {
            var $refinementDefinition = $(refinementDefinition),
                activeRefinementValues = $refinementDefinition.find('.jwsdw-productRefinement-value input[name^=jwsdw-refinement-value]:checked'),
                refinementDefinitionId = $refinementDefinition.data('refinementDefinition'),
                values = [],
                fromValues = [],
                toValues = [];

            if (refinementDefinitionId !== 'price' && activeRefinementValues.length > 0) {
                activeRefinementValues.each(function (j, activeRefinementValue) {
                    values.push($(activeRefinementValue).data('refinementValue'));
                });

                activeRefinements['prefn' + (index + 1)] = refinementDefinitionId;
                activeRefinements['prefv' + (index + 1)] = values.join('|');
            } else if (refinementDefinitionId === 'price' && activeRefinementValues.length > 0) {
                activeRefinementValues.each(function (j, activeRefinementValue) {
                    fromValues.push($(activeRefinementValue).data('refinementValueFrom') || 0);
                    toValues.push($(activeRefinementValue).data('refinementValueTo'));
                });
                activeRefinements.pmin = fromValues.sort()[0];
                activeRefinements.pmax = toValues.sort()[toValues.length - 1];
            }
        });


        return activeRefinements;
    };

    /**
     * @description Method to patch the frontend UX by removing empty product tiles
     * This is a necessary workaround because we initially show all variants (orderable or not) but hide them on refined searches
     * @returns {void}
     */
    function hideEmptyProductTiles() {
        var hiddenTiles = 0;
        $productSearchResult.find('.jwsdw-productSearchResult-grid .jwsdw-product-tiles-grid-product-counter').each(function () {
            if ($(this).find('.jwsdw-product-variation').length === 0) {
                $(this).remove();
                hiddenTiles++;
            }
        });

        $('.jwsdw-progressBar-shown').text(parseInt($('.jwsdw-progressBar-shown').text()) - hiddenTiles);
        $('.jwsdw-progressBar-results').text(parseInt($('.jwsdw-progressBar-results').text()) - hiddenTiles);
    }

    app.search.hideEmptyProductTiles = hideEmptyProductTiles;

    /**
     * @description Method to initialize the content for the product refinements picker
     * @returns {Boolean} true after execution
     */
    function initializeSearchRefinementsPicker() {
        var $productRefinements = $productSearchResult.find('#jwsdw-productRefinements'),
            searchBaseUrl = app.search.getSearchBaseUrl();

        // only overwrite picker content when updated refinement data is available otherwise keep current values
        // that ensures that on a no result page the picker is still available
        if ($productRefinements && $productRefinements.length > 0) {
            // move refinements html to the picker and remove source from DOM
            $productRefinementsPicker.find('.jwsdw-searchRefinementsPicker-content').html($productRefinements.html());
            $('.jwsdw-searchRefinementsPicker-results').text($('.jwsdw-progressBar-results').text());
            $productRefinements.remove();

            $productRefinementsPicker.find('.jwsdw-searchRefinementsPicker-apply').data('searchBaseUrl', searchBaseUrl);

            window.jwsdwMediator.subscribe('closePicker', function (type, applyFilters) {
                if (type === 'searchRefinementsPicker' && !applyFilters) {
                    $productRefinementsPicker.find('.jwsdw-productRefinement-value input[name^=jwsdw-refinement-value]').prop('checked', false);
                    $productRefinementsPicker.find('.jwsdw-refinement-initiallyActive').prop('checked', true);
                }
            });
        } else if ($productRefinementsPicker.find('.jwsdw-spinner').length > 0 && (!$productRefinements || $productRefinements.length === 0)) {
            $('.jwsdw-productSearchResult-change-filters').addClass('jws-hidden');
        } else {
            $('.jwsdw-productSearchResult-change-filters').removeClass('jws-hidden');
        }

        return true;
    }

    /**
     * @description Method to initialize the refinement bar swiper
     * @returns {void}
     */
    function initTheSwiper() {
        var options = window.jwsdwApi.slider.options.categorySlider;
        options.centeredSlides = false;
        options.centeredSlidesBounds = false;
        window.jwsdwApi.slider.initSwiper(
            '.jwsdw-search-category-slider',
            options
        );
    }

    app.search.init = function () {
        initRefinementBarAndSelectFilter();
        initializeSearchRefinementsPicker();
        loadSortingSelect();
        initializeEvents();
        hideEmptyProductTiles();
    };

    app.search.updateProductSearchResult = function (url, popState, callback) { // * This is the function that gets called whenever we set / remove filters.
        if ($productSearchResult.length > 0) {
            url = window.jwsdwUtil.urlUtils.removeParamFromUrl(url, 'page');
            if (window.history.pushState) {
                if ($cache.hasOwnProperty('xhr') && $cache.xhr.hasOwnProperty('abort')) {
                    $cache.xhr.abort();
                }
                $('.jwsdw-transparent-loading-overlay').removeClass('jwsdw-transparent-loaded');
                if (!popState) {
                    window.history.pushState({}, $(document).find('title').text(), url);
                }

                url = window.jwsdwUtil.urlUtils.appendParamToUrl(url, 'format', 'ajax-page');

                if (pageType) {
                    url = window.jwsdwUtil.urlUtils.appendParamToUrl(url, 'type', pageType);
                }
                $cache.xhr = $.ajax({
                    'url': url,
                    'success': function (data) {
                        let keyValue = $(window).width() < 900 ? window.jwsdwUtil.getCookie('gridSelectionMobile') : window.jwsdwUtil.getCookie('gridSelection');
                        app.search.updateProductSearchResultHTML(data, callback);
                        initRefinementBarAndSelectFilter();
                        if (keyValue) {
                            handleLayoutSelection($(keyValue), $(window).width() < 900);
                        }
                        handleGridSystem();
                    }
                });
            } else {
                window.location.href = url;
            }
        }
    };

    app.search.updateProductSearchResultCount = function (url) { // * This function updates the result count from the search refinements picker when filters are applied or removed.
        if ($productSearchResult.length > 0) {
            url = window.jwsdwUtil.urlUtils.removeParamFromUrl(url, 'page');
            if (window.history.pushState) {
                if ($cache.hasOwnProperty('xhr') && $cache.xhr.hasOwnProperty('abort')) {
                    $cache.xhr.abort();
                }
                $('.jwsdw-searchRefinementsPicker-apply .jwsdw-spinner').removeClass('jws-hidden');
                $('.jwsdw-searchRefinementsPicker-apply').attr('disabled', 'disabled');

                $cache.xhr = $.ajax({
                    'url': url,
                    'success': function (data) {
                        $('.jwsdw-searchRefinementsPicker-results').text($(data).find('.jwsdw-progressBar-results').text());
                        $('.jwsdw-searchRefinementsPicker-apply .jwsdw-spinner').addClass('jws-hidden');
                        $('.jwsdw-searchRefinementsPicker-apply').removeAttr('disabled');
                    }
                });
            } else {
                window.location.href = url;
            }
        }
    };

    app.search.updateProductSearchResultHTML = function (data, callback) {
        $('.jwsdw-refinements-category').html($(data).find('.jwsdw-refinements-category').html());
        $productSearchResult.find('.jwsdw-productSearchResult-sort').html($(data).find('.jwsdw-productSearchResult-sort').html());
        $productSearchResult.find('.jwsdw-productSearchResult-refinements').html($(data).find('.jwsdw-productSearchResult-refinements').html());
        $productSearchResult.find('.jwsdw-attributeRefinements').html($(data).find('.jwsdw-attributeRefinements').html());
        $productSearchResult.find('.jwsdw-chips-items').html($(data).find('.jwsdw-chips-items').html());
        $productSearchResult.find('.jwsdw-productSearchResult-grid').html($(data).find('.jwsdw-productSearchResult-grid').html());
        window.jwsdwMediator.publish('wishlist:markTiles');
        $('.jwsdw-search-tab-contentCount').text($(data).find('.jwsdw-contentSearchResult-grid').data('content-count'));
        $('.jwsdw-search-tab-productCount').text(parseInt($(data).find('.jwsdw-productSearchResult-grid').data('product-count')));
        $('.jwsdw-transparent-loading-overlay').addClass('jwsdw-transparent-loaded');
        initializeSearchRefinementsPicker();
        loadSortingSelect();
        hideEmptyProductTiles();
        if (callback) {
            callback();
        }
        window.scriptOnLoad();
        return true;
    };

    app.search.updateFilters = function (data) {
        $('.jwsdw-productRefinement').each(function () {
            var refinementDefinitionId = $(this).data('refinement-definition'),
                refinementDefinition;

            // ignore price refinement
            if (refinementDefinitionId === 'price') {
                return;
            }

            // search for current refinement definition in data
            refinementDefinition = data.refinements.find(function (refDef) {
                return refDef.id === refinementDefinitionId;
            });

            $(this).find('.jwsdw-productRefinement-value').each(function () {
                var input = $(this).find('input'),
                    value,
                    refinementValue,
                    hitCount;

                // if no refinement definition exists in data disable all refinement values
                if (!refinementDefinition) {
                    input.attr('disabled', 'disabled');
                    return;
                }

                value = input.data('refinement-value');

                // search for current refinement value in data
                refinementValue = refinementDefinition.refinementValues.find(function (refVal) {
                    return value && value.toString() === refVal.value;
                });

                hitCount = refinementValue ? refinementValue.hitCount : 0;

                // if a hit count exists and is greater 0 the value is enabled otherwise it gets disabled
                if (hitCount > 0) {
                    input.removeAttr('disabled');
                } else {
                    input.attr('disabled', 'disabled');
                    input.prop('checked', false);
                }
            });
        });

        // set the current product count on the apply filter button
        $('.jwsdw-searchRefinementsPicker-results').text(data.productCount);
        if (data.productCount === 0) {
            $('.jwsdw-searchRefinementsPicker-apply').attr('disabled', 'disabled');
        } else {
            $('.jwsdw-searchRefinementsPicker-apply').removeAttr('disabled');
        }
        return true;
    };

    app.search.loadMoreProducts = function (url, historyUrl, $context) {
        var $loadMoreButtonText = $context.find('.jwsdw-buttonText');
        var $oldLoadMoreBtn;

        if ($productSearchResult.length > 0) {
            if (window.history.pushState) {
                if ($cache.hasOwnProperty('xhr') && $cache.xhr.hasOwnProperty('abort')) {
                    $cache.xhr.abort();
                }

                url = window.jwsdwUtil.urlUtils.appendParamToUrl(url, 'format', 'ajax-grid');

                if (pageType) {
                    url = window.jwsdwUtil.urlUtils.appendParamToUrl(url, 'type', pageType);
                }

                $cache.xhr = $.ajax({
                    'url': url,
                    'beforeSend': function () {
                        $context.addClass('jwsdw-spinner');
                        $loadMoreButtonText.addClass('jwsdw-invisible');
                    },
                    'success': function (data) {
                        var newProductGrid = $(data).find('.jwsdw-product-tiles-grid-wrapper'),
                            newLoadMore = $(data).find('.jwsdw-loadMore');

                        $productSearchResult
                            .find('.jwsdw-product-tiles-grid-wrapper')
                            .append(newProductGrid.html());

                        window.jwsdwMediator.publish('wishlist:markTiles');

                        $oldLoadMoreBtn = $productSearchResult.find('.jwsdw-loadMore .jwsdw-loadMore-button'); // this is the old 'Load More' button

                        $productSearchResult
                            .find('.jwsdw-loadMore')
                            .html(newLoadMore.html());

                        hideEmptyProductTiles();

                        window.history.replaceState(null, $(document).find("title").text(), historyUrl);

                        if ($(document).find('.jwsdw-product-tiles-grid-product-highlight')) {
                            $(document).find('.jwsdw-product-tiles-grid-wrapper .jwsdw-product-tiles-grid-product-counter').each(function (index) {
                                if ($(this).hasClass('jwsdw-product-tiles-grid-product-highlight') && (((index + 3) % 8) - 5) === 0) {
                                    $(document).find('.jwsdw-productSearchResult-grid .jwsdw-product-tiles-grid-product-counter:nth(' +
                                        (index + 5) + ')').addClass('jwsdw-clearLeft-forXLargeOnly');
                                }
                                if ($(this).hasClass('jwsdw-product-tiles-grid-product-highlight') && (((index + 1) % 8) - 5) === 0) {
                                    $(document).find('.jwsdw-productSearchResult-grid .jwsdw-product-tiles-grid-product-counter:nth(' +
                                        (index + 5) + ')').addClass('jwsdw-clearLeft-forXLargeOnly');
                                }
                            });
                        }

                        // binding product tile to touchStart and touchMove events
                        bindTouchEvents();

                        initLoadMoreIntersectionObserver($oldLoadMoreBtn);

                        return true;
                    },
                    'complete': function () {
                        handleLayoutSelection($('.jwsdw-layoutGrid svg[class*=black]:not(.jws-visuallyHidden):visible'), $(window).width() < 900);
                        $('.jwsdw-product-tiles-grid-product-counter').css('clear', 'none');
                        handleGridSystem();
                        $context.removeClass('jwsdw-spinner');
                        $loadMoreButtonText.removeClass('jwsdw-invisible');
                    }
                });
            } else {
                window.location.href = historyUrl;
            }
        }
    };

    app.search.loadMoreContent = function (url, historyUrl, $context) {
        var $loadMoreButtonText = $context.find('.jwsdw-buttonText');

        if ($contentSearchResult.length > 0) {
            if (window.history.pushState) {
                if ($cache.hasOwnProperty('xhr') && $cache.xhr.hasOwnProperty('abort')) {
                    $cache.xhr.abort();
                }

                window.jwsdwUtil.urlUtils.appendParamsToUrl(url, {
                    'search-type': 'content',
                    'format': 'ajax-page'
                });

                if (pageType) {
                    url = window.jwsdwUtil.urlUtils.appendParamToUrl(url, 'type', pageType);
                }

                $cache.xhr = $.ajax({
                    'url': url,
                    'beforeSend': function () {
                        $context.addClass('jwsdw-spinner');
                        $loadMoreButtonText.addClass('jwsdw-invisible');
                    },
                    'success': function (data) {
                        var newContentGrid = $(data).find('.jwsdw-contentGrid-wrapper'),
                            newLoadMore = $(data).find('.jwsdw-contentSearchResult-loadMore');

                        $contentSearchResult
                            .find('.jwsdw-contentGrid-wrapper')
                            .append(newContentGrid.html());

                        $contentSearchResult
                            .find('.jwsdw-contentSearchResult-loadMore')
                            .html(newLoadMore.html());

                        window.history.replaceState(null, $(document).find("title").text(), historyUrl);

                        return true;
                    },
                    'complete': function () {
                        $context.removeClass('jwsdw-spinner');
                        $loadMoreButtonText.removeClass('jwsdw-invisible');
                    }
                });
            } else {
                window.location.href = historyUrl;
            }
        }
    };

    app.search.getSearchBaseUrl = function (baseUrl) {
        var urlParameters,
            searchParameter;

        //uses the base url passed to the function or gets it from window.location
        baseUrl = baseUrl || window.location.href.split('?')[0];

        //slices and splits all parameters e.g. ['q=query', 'page=4']
        urlParameters = window.location.search.slice(1).split('&');

        //only keep the original search query parameter if existent
        searchParameter = urlParameters.find(function (parameter) {
            return parameter.indexOf('q') > -1;
        });

        return baseUrl + (searchParameter ? '?' + searchParameter : '');
    };

    app.search.init();

    /**
     * Method handle layout selection
     * @param {Object} $this this
     * @param {Object} isMobile true if viewport is medium or smaller
     * @returns {void}
    */
    function handleLayoutSelection ($this, isMobile) {
        let numberSelection = $this.attr('class').split(/\s+/)[0].match(/\d/g)[0],
            mobileClasses = 'jwsdw-product-grid-1 jwsdw-product-grid-2 jwsdw-product-grid-mobile-3',
            largeClasses = 'jwsdw-product-grid-3 jwsdw-product-grid-4';

        isMobile ? $gridWrapper.removeClass(mobileClasses) : $gridWrapper.removeClass(largeClasses);

        if (numberSelection === '3') {
            $gridWrapper.removeClass(mobileClasses + ' ' + largeClasses).addClass('jwsdw-product-grid-3 jwsdw-product-grid-mobile-3 large-view');
        } else {
            $gridWrapper.addClass('jwsdw-product-grid-' + numberSelection);
            $gridWrapper.toggleClass('large-view', numberSelection !== '4');
        }
    }
    /**
     * Method handle grid system
     * @returns {void}
     */
    function handleGridSystem () {
        if ($(window).width() >= 900 && $gridWrapper.hasClass('jwsdw-product-grid-4')) {
            $('.jwsdw-3x3layout-black, .jwsdw-4x4layout').addClass('jws-visuallyHidden');
            $('.jwsdw-3x3layout, .jwsdw-4x4layout-black').removeClass('jws-visuallyHidden');
        } else if ($(window).width() >= 900 && $gridWrapper.hasClass('jwsdw-product-grid-3')) {
            $('.jwsdw-3x3layout-black, .jwsdw-4x4layout').removeClass('jws-visuallyHidden');
            $('.jwsdw-3x3layout, .jwsdw-4x4layout-black').addClass('jws-visuallyHidden');
        }

        if ($(window).width() < 900 && $gridWrapper.hasClass('jwsdw-product-grid-mobile-3')) {
            $('.jwsdw-2x2layout-black, .jwsdw-3x3layout').addClass('jws-visuallyHidden');
            $('.jwsdw-2x2layout, .jwsdw-3x3layout-black').removeClass('jws-visuallyHidden');
        } else if ($(window).width() < 900 && ($gridWrapper.hasClass('jwsdw-product-grid-2') || $gridWrapper.hasClass('jwsdw-product-grid-1'))) {
            $('.jwsdw-2x2layout-black, .jwsdw-3x3layout').removeClass('jws-visuallyHidden');
            $('.jwsdw-2x2layout, .jwsdw-3x3layout-black').addClass('jws-visuallyHidden');
        }

        if ($(window).width() < 600 && ($gridWrapper.hasClass('jwsdw-product-grid-2') || $gridWrapper.hasClass('jwsdw-product-grid-mobile-3'))) {
            $('.jwsdw-1x1layout-black, .jwsdw-2x2layout').addClass('jws-visuallyHidden');
            $('.jwsdw-1x1layout, .jwsdw-2x2layout-black').removeClass('jws-visuallyHidden');
        } else if ($(window).width() < 600 && $gridWrapper.hasClass('jwsdw-product-grid-1')) {
            $('.jwsdw-1x1layout-black, .jwsdw-2x2layout').removeClass('jws-visuallyHidden');
            $('.jwsdw-1x1layout, .jwsdw-2x2layout-black').addClass('jws-visuallyHidden');
        }
    }

    window.jwsdwMediator.publish('executeOnBreakpointSwitch', handleGridSystem);
}(app, jQuery));


(function () {
    var _urlOnPageLoad = document.location.href,
        _currHash = window.location.hash;

    window.onpopstate = function (event) {
        // workaround for safari that triggers a popstate already when navigating back from another page
        if (_urlOnPageLoad === document.location.href) {
            _urlOnPageLoad = null;
            return;
        }
        if (_currHash !== window.location.hash) {
            // hash has changed, do nothing
            event.preventDefault();
            return false;
        }

        _currHash = window.location.hash;

        window.app.search.updateProductSearchResult(document.location.href, true);
    };
}());

(function () {
    $(document).on('change', 'input[name^=jwsdw-refinement-value]', function () {
        if ($(this).prop('checked')) {
            window.dataLayer.push({
                'event': 'ce.filtersorting',
                'eventCategory': 'Filter & Sorting',
                'eventAction': window.dynamic_tm_data.categoryid,
                'eventLabel': $(this).parents('.jwsdw-productRefinement').data('refinement-definition'),
                'eventValue': undefined,
                'nonInteraction': false,
                'selection': $(this).data('refinement-value')
            });
        }
    });

    $(document).on('change', '.jwsdw-productSearchResult-sort-select', function () {
        window.dataLayer.push({
            'event': 'ce.filtersorting',
            'eventCategory': 'Filter & Sorting',
            'eventAction': window.dynamic_tm_data.categoryid,
            'eventLabel': 'Sorting',
            'eventValue': undefined,
            'nonInteraction': false,
            'selection': $(this).find('option:selected').text().trim()
        });
    });
}());

/**
 * Method to load and customize Sort select
 * @returns {void} returns nothing
 */
function loadSortingSelect() {
    let sortingContainers,
        selectElement,
        selectedDiv,
        sortByCustomDiv,
        selectableItemContainer,
        selectableItem,
        selectableInput,
        sameAsSelected,
        url;

    sortingContainers = document.querySelectorAll('.jwsdw-productSearchResult-sort');

    sortingContainers.forEach(container => {
        selectElement = container.querySelector('select');
        selectedDiv = container.querySelector('.select-selected');
        sortByCustomDiv = container.querySelector('.jwsdw-sortByCustom');
        sortByCustomDiv.innerHTML = selectElement.options[selectElement.selectedIndex].innerHTML;

        selectableItemContainer = container.querySelector('.select-items');

        Array.from(selectElement.options).slice(1).forEach((option, index) => {
            selectableItem = document.createElement('label');
            selectableItem.setAttribute('for', `sort-radio${index + 1}`);

            selectableInput = document.createElement('input');
            selectableInput.type = 'radio';
            selectableInput.id = `sort-radio${index + 1}`;
            selectableInput.name = 'sort-radio';

            selectableItem.textContent = option.textContent;

            if (sortByCustomDiv.innerHTML === option.innerHTML) {
                selectableInput.checked = true;
            }

            selectableItem.addEventListener('click', function () {
                selectElement.selectedIndex = index + 1;
                selectedDiv.textContent = selectElement.ariaLabel;
                sameAsSelected = this.parentNode.getElementsByClassName('same-as-selected');
                Array.from(sameAsSelected).forEach(el => el.classList.remove('same-as-selected'));
                this.classList.add('same-as-selected');

                if ($(window).width() < 900) {
                    window.jwsdwMediator.publish('closePicker', 'searchRefinementsPicker', true, true);
                }

                url = window.jwsdwUtil.urlUtils.getUri(option.value).fullUrl;
                app.search.updateProductSearchResult(url);
            });

            selectableItemContainer.appendChild(selectableInput);
            selectableItemContainer.appendChild(selectableItem);
        });

        selectedDiv.addEventListener('click', function (e) {
            e.stopPropagation();
            $('.jwsdw-tooltip').removeClass('jwsdw-tooltipActive');
            $('.jwsdw-productRefinements-pickerButton.jwsdw-selected-refinement').removeClass('jwsdw-selected-refinement');
            $('.jwsdw-refinementsBarContainer').not('.jws-hidden').addClass('jws-hidden');
            closeAllSelect(this);
            this.nextElementSibling.classList.toggle('select-hide');
            this.classList.toggle('select-arrow-active');
        });
    });
    document.addEventListener('click', closeAllSelect);

    handleStickyFilterBar();
}

/**
 * @description Helper function to handle dynamic sticky filter bar state/animations.
 * @returns {undefined}
 */
function handleStickyFilterBar () {
    const $filterBar = $('.jwsdw-productSearchResult-refinements'),
        $pageWrapper = $filterBar.closest('.jwsdw-pageWrapper');

    if ($filterBar.length) {
        let pageWrapper = $pageWrapper.length && $pageWrapper[0],
            pageWrapperMaxWidth = pageWrapper ? Number(getComputedStyle(pageWrapper).maxWidth.split('px').shift()) : 'none',
            isPageWrapped = pageWrapper ? pageWrapperMaxWidth !== 'none' : false;

        window.jwsdwMediator.publish('observeStickyElement', $filterBar, { 'stickyClass': 'jws-boxShadow-1' });

        $filterBar.on('stickyElement:stuck', () => {
            if (isPageWrapped && window.innerWidth > pageWrapperMaxWidth) {
                requestAnimationFrame(() => {
                    $filterBar.css('width', `${$filterBar.outerWidth()}px`);
                    requestAnimationFrame(() => {
                        $filterBar
                            .addClass('enlarged')
                            .css('transform', `translateX(-${(window.innerWidth - pageWrapperMaxWidth) / 2}px`)
                            .css('width', `${window.innerWidth}px`);
                    });
                });
            }
        });

        $filterBar.on('stickyElement:static', () => {
            if ($filterBar.hasClass('enlarged')) {
                $filterBar.removeClass('enlarged');
                $filterBar[0].style.transform = null;
                $filterBar[0].style.width = null;
            }
        });

        /* Resize observer necessary for adjusting the width and transform of the filter bar accordingly whenever the window is resized */
        const filterBarResizeObserver = new ResizeObserver(() => { //eslint-disable-line
            pageWrapperMaxWidth = pageWrapper ? Number(getComputedStyle(pageWrapper).maxWidth.split('px').shift()) : 'none';
            isPageWrapped = pageWrapper ? pageWrapperMaxWidth !== 'none' : false;
            if ($filterBar.hasClass('enlarged') && window.innerWidth <= pageWrapperMaxWidth) {
                $filterBar.removeClass('enlarged');
                $filterBar[0].style.transform = null;
                $filterBar[0].style.width = null;
            } else if ($filterBar.hasClass('enlarged')) {
                $filterBar
                    .css('transform', `translateX(-${(window.innerWidth - pageWrapperMaxWidth) / 2}px`)
                    .css('width', `${window.innerWidth}px`);
            }
        });

        filterBarResizeObserver.observe(document.body);
    }
}

/**
 * Method to close select
 * @param {Object} elmnt this
 * @returns {void} returns nothing
*/
function closeAllSelect(elmnt) {
    let z,
        y,
        k,
        xl,
        yl,
        arrNo = [];

    z = document.getElementsByClassName('select-items');
    y = document.getElementsByClassName('select-selected');
    xl = z.length;
    yl = y.length;
    for (k = 0; k < yl; k++) {
        if (elmnt === y[k]) {
            arrNo.push(k);
        } else {
            y[k].classList.remove('select-arrow-active');
        }
    }
    for (k = 0; k < xl; k++) {
        if (arrNo.indexOf(k)) {
            z[k].classList.add('select-hide');
        }
    }
}

/**
 * Method to update datalayer with chosen performance
 * @param {string} category current category
 * @param {string} performance current performance
 * @returns {void} returns nothing
*/
function updatePerformanceTracking(category, performance) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        'event': 'ce.filtersorting',
        'eventCategory': 'Filter & Sorting',
        'eventAction': category,
        'eventLabel': 'Secondary-Filter',
        'eventValue': undefined,
        'nonInteraction': false,
        'selection': performance
    });
}

$(function () {
    const breadcrumbsScrollContainer = document.querySelector('.jwsdw-breadcrumbs-search-horizontallyScroll');
    if (breadcrumbsScrollContainer) {
        const resizeObserver = new ResizeObserver((entries) => { //eslint-disable-line
            for (const entry of entries) {
                if (entry.contentBoxSize) {
                    if (breadcrumbsScrollContainer.scrollWidth !== breadcrumbsScrollContainer.clientWidth) {
                        breadcrumbsScrollContainer.scrollLeft = breadcrumbsScrollContainer.scrollWidth;
                    }
                    $('.jwsdw-breadcrumbs-search-horizontallyScroll').addClass('jwsdw-fadeInAnimation');
                    $('.jwsdw-breadcrumbs-search-horizontallyScroll').removeClass('jwsdw-fadeInAnimationHide');
                }
            }
        });
        resizeObserver.observe(breadcrumbsScrollContainer);
    }
});